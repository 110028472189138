@import 'src/styles/abstracts/variables';

.form-control,
.form-select {
  border-bottom-width: $input-height-border;
  border-color: $input-border-color;
  color: $gray-600;

  &.ng-invalid.ng-dirty {
    border-bottom-color: $danger;
  }

  &.ng-valid.ng-touched {
    border-bottom-color: $success;
  }
}

.ng-invalid.ng-dirty {
  ~ .invalid-feedback,
  ~ .invalid-tooltip,
  .invalid-feedback, // Form level validation
  .invalid-tooltip {
    display: block;
  }
}

.ng-valid.ng-dirty {
  ~ .valid-feedback,
  ~ .valid-tooltip,
  .valid-feedback, // Form level validation
  .valid-tooltip {
    display: block;
  }
}

.lux-type-percent {
  background-image: url('https://static.learnlux.com/images/icons/percent-v1.1.png');
  background-position: bottom .75rem right .5rem;
  background-repeat: no-repeat;
  background-size: 20px;
  padding-right: 2rem;
  width: 6rem;
  text-align: end; // Position next to % symbol.

  // Make space for the native number controls
  &:focus {
    padding-left: .25rem;
  }
}

.block-field {
  .lux-type-percent {
    width: 100%;
  }
}

.form-floating {
  display: block; // Needs to be some sort of block-like display to work.

  .lux-type-percent {
    background-image: none;
    padding-right: 2rem; // Override's the stronger bootstrap rule for padding.

    &:not(:placeholder-shown),
    &:focus {
      background-image: url('https://static.learnlux.com/images/icons/percent-v1.1.png');
    }
  }
}

.form-check {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-size: $lead-font-size;
}

.form-check-input {
  flex: 0 0 2rem; // Prevent the flex area for the input from shrinking
  background-color: transparent;
  cursor: pointer;

  &[type='checkbox'] {
    --bs-form-check-bg-image: #{escape-svg($form-check-input-unchecked-bg-image)};
  }

  &[type='radio'] {
    --bs-form-check-bg-image: #{escape-svg($form-check-radio-unchecked-bg-image)};

    &:checked {
      &.check {
        --bs-form-check-bg-image: #{escape-svg($form-check-radio-check-checked-bg-image)};
      }
    }
  }
}

.form-check-label {
  flex: 1;
  cursor: pointer;
  font-size: inherit;
}

.lux-btn-group {
  text-align: center;

  .btn {
    margin: .25rem;
  }

  .btn-outline-dark {
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-active-bg: var(--bs-primary);
    --bs-btn-active-border-color: var(--bs-primary);
  }

  .btn-check {
    &:checked,
    &:active {
      + .btn-outline-dark {
        background-image: linear-gradient(135deg, $primary, $teal);
      }
    }

    &[type='radio'] {
      + .btn-outline-dark {
        --bs-btn-padding-x: 3rem;
      }

      &:checked,
      &:active {
        + .btn-outline-dark {
          position: relative;

          &::before {
            position: absolute;
            top: 1.2rem;
            left: 1rem;
            font-family: $icon-font-family;
            font-size: 1.5rem;
            content: 'check';
          }
        }
      }
    }
  }
}
