.modal-header {
  display: block; // Undo flex from bootstrap
  position: relative;

  .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    margin: 0; // Overwrite bootstrap calculated values
    padding: 0; // Overwrite bootstrap calculated values
  }

  + .modal-body {
    margin-block-start: 1rem;
  }
}

.modal-title {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;

  &::first-letter {
    text-transform: uppercase;
  }
}

.modal-header-description {
  margin-block: 0;
}

.modal-footer {
  justify-content: center;

  .modal-body + & {
    margin-block-start: .375rem;
  }
}

// stylelint-disable no-descending-specificity
.modal-header,
.modal-body,
.modal-footer {
  padding-block: 0; // Overwrite $modal-inner-padding on the top and bottom direction.

  &:first-of-type {
    padding-block-start: var(--bs-modal-padding);
  }

  &:last-of-type {
    padding-block-end: var(--bs-modal-padding);
  }
}
