@charset 'UTF-8';

// Note: Import Bootstrap configuration
@import './styles/abstracts/variables';
@import './styles/abstracts/mixins';

// Customized Boostrap setup
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
// @import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
// @import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
// @import 'bootstrap/scss/breadcrumb';
// @import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
// @import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
// @import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
// @import 'bootstrap/scss/popover';
// @import 'bootstrap/scss/carousel';
// @import 'bootstrap/scss/spinners';
// @import 'bootstrap/scss/offcanvas';
// @import 'bootstrap/scss/placeholders';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/utilities/api';

// Bootstrap Overrides
@import './styles/abstracts/typography';
@import './styles/abstracts/buttons';
@import './styles/abstracts/forms';
@import './styles/abstracts/modals';

:root {
  --lux-topnav-height: #{$topnav-height};
}

html {
  // These properties must be applied to the thing that scrolls!
  // scroll-snap-type: y proximity; // This makes things wierd! See body rule below.
  scroll-behavior: smooth;
  scroll-padding-top: var(--lux-topnav-height);
  overflow-anchor: none; // stylelint-disable plugin/no-unsupported-browser-features -- Safari doesn't support
  overscroll-behavior: contain;
}

// body {
//   // Try to prevent Chrome from re-scrolling to anchor on layout change.
//   // Steps to reproduce:
//   // 1. https://localhost:4200/dashboard#step-goals
//   // 2. Scroll up to the header, inspect element, increase the height (padding, etc)
//   // 3. Bad behavior: Chrome scrolls back down to goals.
//   // This is especially bad for the mobile size header menu
//   // https://stackoverflow.com/a/43001201
//   // https://stackoverflow.com/a/63966996
//   // https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-anchor/Guide_to_scroll_anchoring#what_if_i_need_to_disable_it
//   overflow-anchor: none;
//   // Right now, this _does not work!_
//   // Disabling scroll-snap-type in html because the experience is bad
// }

// Implement standard h1 Typography to be used across the application
h1 {
  @include media-breakpoint-down('sm') {
    font-size: $mobile-h1-font-size;
  }

  font-family: $display-font-family;
  font-weight: $h1-font-weight;
}

a,
.btn-link,
.btn-text {
  // lower the underline for links some
  text-underline-offset: 3px;
  text-underline-position: under;
}

.material-symbols-rounded {
  margin-bottom: 2px;
  vertical-align: middle;
}

.accordion-button:hover {
  // lower the underline for links some
  text-underline-offset: 3px;
  text-underline-position: under;
  text-decoration: underline;
  color: $primary;
}

// Careful using this as with the position: fixed header small screens may not be able to view the
// bottom of the sticky content.
.sticky-top,
.sticky-sm-top,
.sticky-md-top,
.sticky-lg-top,
.sticky-xl-top {
  top: var(--lux-topnav-height);
}

.scroll-anchor {
  scroll-snap-align: start;
}

// rotation credit: https://stackoverflow.com/questions/6410730/css-endless-rotation-animation
// Spinner for My Accounts
.lux-sync {
  animation: spin linear 1.2s infinite;
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

// Used in auth.component.html, footer.component.html, and accounts.component.html
.support-wrapper {
  background-color: $body-bg;
  padding: 15px 0;
  text-align: center;

  .mail-lnk {
    margin: 0 1.5rem;
    padding-left: 0;
  }

  .material-symbols-rounded {
    margin-right: 5px;
    border-radius: 50%;
    background-color: $yellow-mustard;
    padding: 5px;
    color: $warning;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }
}

// Marquee headers for Dashboard, Lessons List, and Accounts pages
.lux-marquee {
  @include media-breakpoint-up('lg') {
    padding: 3.75rem;
  }

  background-image: url('https://static.learnlux.com/images/backgrounds/background-stars-v1.1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
  text-align: center;
  color: $white;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 40rem;
    height: 100%;
  }

  h1 {
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
    width: 100%;
    font-weight: 200;
  }
}

@include media-breakpoint-up('md') {
  .marquee-hills {
    position: relative;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .3;
      background-image: url('https://static.learnlux.com/images/animations/background-clouds-v1.0.svg');
      background-position: -200px bottom;
      background-repeat: no-repeat;
      background-size: 10000px auto;
      height: 100px;
      content: '';
    }
  }
}

.marquee-money {
  background-image: url('https://static.learnlux.com/images/backgrounds/background-money-v1.1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
}

.short-bar::after {
  display: block;
  margin: .5rem 0 1.5rem;
  border-bottom: .625rem solid $warning;
  width: 3rem;
  content: '';
}

.form-label.required::after {
  color: $red;
  content: '*';
}
