.btn {
  box-shadow: 0 5px 1px rgba($black, .1);

  .disabled {
    opacity: .8;
    pointer-events: none;
  }
}

// All .btn have a shadow, except the ones that don't
.btn-link,
.btn-text,
.btn-outline-primary {
  box-shadow: none;
}

// Primary Red Action Button
.btn-secondary {
  // Bootstrap v5 darkened the button on hover instead of making it lighter.
  --bs-btn-hover-bg: #{$secondary-hover};
}

.btn-dark {
  // Bootstrap calculates that the correct contrasting color for this gray button is black.
  // However we have decided to use white, which only has a contrast of 2.71:1
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
}

.btn-outline-primary,
.btn-outline-dark {
  --bs-btn-bg: var(--bs-body-bg);
  --bs-btn-border-color: var(--bs-border-color);
  --bs-btn-focus-shadow-rgb: var(--bs-primary-rgb);
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-color: var(--bs-primary);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-border-radius: #{$border-radius};

  box-shadow: 0 2px 4px rgba($black, .12);
}

// Planner Details Page
.btn-check {
  + .btn-outline-light {
    --bs-btn-color: var(--bs-black);
    --bs-btn-font-weight: 400;

    box-shadow: 0 2px 4px rgba($black, .12);
  }

  &:checked + .btn-outline-light { // stylelint-disable csstools/use-nesting -- No idea how to make this rule happy
    --bs-btn-active-bg: var(--bs-white);
    --bs-btn-active-border-color: var(--bs-primary);
    --bs-btn-active-color: var(--bs-primary);
    --bs-btn-font-weight: 700;
  }
}

.btn-outline-primary:disabled {
  --bs-btn-disabled-border-color: var(--bs-border-color);
}

.btn-bubble {
  --bs-btn-border-radius: var(--bs-border-radius-pill);
  --bs-btn-font-size: #{$lead-font-size};

  &.btn-sm {
    // Doing something wrong here probably, but this size setting is needed so .btn-sm works on .btn-bubble
    --bs-btn-font-size: 1rem;
  }
}

.action-circle {
  --bs-btn-border-radius: 50%;
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;

  box-shadow: 0 4px 10px rgba($black, .2);
  width: 3.75rem;
  height: 3.75rem;

  .material-symbols-rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 2.75rem;
  }

  &.btn-outline-primary {
    --bs-btn-bg: var(--bs-body-bg);
    --bs-btn-color: #{$green-400};
    --bs-btn-border-width: 0;
    --bs-btn-hover-border-color: var(--bs-gray-100);
  }

  &.btn-lg {
    width: 5.375rem;
    height: 5.375rem;

    .material-symbols-rounded {
      font-size: 3rem;
      font-weight: 600;
    }
  }
}

.btn-text {
  --bs-btn-color: var(--bs-dark);

  @extend .btn-link; // stylelint-disable-line scss/at-extend-no-missing-placeholder
}

.btn-link {
  font-weight: 700;
}

.lux-link {
  --bs-btn-font-size: 1rem;
}

// See also modals.scss
.btn-close {
  background: transparent; // We are using material icons instead of bootstrap
  font-size: 2rem;

  // Modals autofocus on the close button by default. I expect others will complain about this being
  // visually highlighted by default.
  &:focus,
  &:hover {
    box-shadow: none;
    color: $primary;
  }
}

.lux-account-btn {
  --lux-shape-one-bg: transparent;

  display: flex;
  gap: 1rem;
  align-items: center;
  margin: .375rem 0;
  border: 1px solid $gray-200;
  border-radius: $border-radius-lg;
  background-color: $body-bg;
  padding: 1rem 2rem;
  width: 100%;

  &:hover {
    border-color: $primary;
    background-color: $light;
  }

  .shape-one {
    border-radius: 50%;
    background-color: var(--lux-shape-one-bg);
    padding: .625rem;
    color: $white;
  }

  .button-text {
    flex: 1;
    text-align: left;

    .call-to-action {
      display: block;
      color: $gray-700;
      font-weight: 700;
    }

    .help-text {
      display: block;
      color: $dark;
      font-size: .875rem;
    }
  }

  .shape-two {
    color: $teal;
  }
}

// Paige's new buttons used in Advisor Connect Component,
// for now these must be paired with the non *-new class to modify them.
.btn-primary-new {
  --bs-btn-border-radius: #{$border-radius};
  --bs-btn-font-weight: 700;

  box-shadow: 0 .5rem 1rem rgba($black, .15);
}

.btn-link-new {
  text-decoration: underline;
  color: inherit;
  font-weight: 700;

  &:hover,
  &:focus {
    color: $primary;
  }
}
