h2,
.h2 {
  font-weight: 300;
}

h5,
.h5,
h6,
.h6 {
  font-weight: 600;
}

.lead {
  strong {
    font-weight: 600;
  }
}
