@charset 'UTF-8';

@use 'sass:map';

@import 'bootstrap/scss/functions';

// Bootstrap Theme bootstrap/scss/variables

// For some reason bootstrap defaults to dropdown menus appearing behind sticky and fixed position
// things. (https://getbootstrap.com/docs/5.2/layout/z-index/) That seems wrong, so make it actually
// appear above everything else since it is user initiated interactivity content.
$zindex-dropdown: 1100;

// These aren't customizations (hence the !default), but are used by our customizations.
$white:      #fff !default;

// Calculate Grays
$blueish-gray: #76868b;
$gray-025: tint-color($blueish-gray, 98%);
$gray-050: tint-color($blueish-gray, 95%);
$gray-100: tint-color($blueish-gray, 90%);
$gray-200: tint-color($blueish-gray, 70%);
$gray-300: tint-color($blueish-gray, 50%);
$gray-400: tint-color($blueish-gray, 30%);
$gray-500: $blueish-gray;
$gray-600: shade-color($blueish-gray, 20%);
$gray-700: shade-color($blueish-gray, 40%);
$gray-800: shade-color($blueish-gray, 60%);
$gray-900: shade-color($blueish-gray, 80%);

// Override Brand Colors
$red:    #f45253; // Good Red
$orange: #ffa500; // Fair Orange
$yellow: #eac63f; // Ronchi Yellow
$green:  #29a59a; // LearnLux Green
$teal:   #06c7a9; // Money Green

$primary:   $green;
$secondary: $red;
$success:   $teal;
$info:      $gray-600;
$dark:      $gray-500;

// These aren't customizations (hence the !default), but are used by our customizations.
$body-bg:    $white !default;
$body-color: $gray-900 !default;

// Reduce contrast so primary-red gets matched with lighter color text.
$min-contrast-ratio:   3;

// Customize headings
$font-family-sans-serif: system-ui, -apple-system, 'Segoe UI', roboto, 'Helvetica Neue', 'Noto Sans',
  'Liberation Sans', arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size:   $font-size-base * 2;
$h2-font-size:   $font-size-base * 2;
$h3-font-size:   $font-size-base * 1.75;
$h4-font-size:   $font-size-base * 1.5;
$h5-font-size:   $font-size-base * 1.25;
$h6-font-size:   $font-size-base !default;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
) !default;
$headings-font-family: $font-family-sans-serif;
$display-font-family:  'Playfair Display', serif;
$display-font-sizes:   $font-sizes;
$display-font-weight:  900;
$text-muted:           $gray-700;
$lead-font-size:       1.125rem;
$h1-font-weight:       700;

// Customize headings for mobile breakpoints
$mobile-h1-font-size:  $font-size-base * 1.5;

// Restore bootstrap v4 link underline setup
$link-decoration:       none;
$link-hover-decoration: underline;

// Override border color
$border-color: $gray-100;

// Large Buttons
$btn-padding-y-lg:     1.3rem;
$btn-padding-x-lg:     3rem;
$font-size-lg:         1.45rem;
$btn-border-radius-lg: .4rem;

// Medium Buttons
$btn-padding-y:     1.3rem;
$btn-padding-x:     2rem;
$btn-font-size:     1.2rem;
$btn-font-weight:   700;
$btn-line-height:   1.125;
$btn-border-radius: .2rem;

// Small Buttons
$btn-padding-y-sm:     .6rem;
$btn-padding-x-sm:     2rem;
$font-size-sm:         1rem;
$btn-border-radius-sm: .25rem;

// Alerts
$alert-bg-scale:    -90%;
$alert-color-scale: 45%; // Make text easier to read. (default is 40%)

// Forms
$legend-font-size:        1rem;
$legend-font-weight:      500;
$input-height-border:     2px;
$input-border-width:      1px;
$input-border-radius:     4px;
$input-border-color:      $gray-200;
$input-font-size:         1.375rem;
$input-placeholder-color: $gray-200;
$form-floating-height:    add(3.875rem, $input-height-border); // increase by increased font size from 3.5rem
$form-floating-padding-y: 1.1875rem; // Given larger height center label
// adjust translateY by the y-axis translation done in our _forms to adjust for different font size label vs input.
$form-floating-label-transform: scale(.85) translateY(-1rem) translateX(.15rem);

// Toggles
$form-check-input-width:                  1.5em; // Cannot use rems here because bootstrap does math with ems
$form-check-input-border:                 unset;
$form-check-input-focus-box-shadow:       none;
$form-check-input-checked-bg-color:       transparent;
$form-check-input-indeterminate-bg-color: transparent;
// Material checkbox and radio SVG icons (https://fonts.google.com/icons?icon.query=check&icon.style=Rounded)
// form-check-input-unchecked-bg-image, form-check-radio-unchecked-bg-image, form-check-radio-check-checked-bg-image are custom for LearnLux
$form-check-input-unchecked-bg-image:     url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><rect x="9" y="9" width="30" height="30" fill="#{$body-bg}"/><path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30Z"/></svg>');
$form-check-radio-unchecked-bg-image:     url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><circle cx="24" cy="24" r="17" fill="#{$body-bg}"/><path d="M24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>');
$form-check-input-checked-bg-image:       url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><rect x="9" y="9" width="30" height="30" fill="#{$body-bg}"/><path fill="#{$primary}" d="M20.95 31.95 35.4 17.5l-2.15-2.15-12.3 12.3L15 21.7l-2.15 2.15Z"/><path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z"/></svg>');
$form-check-radio-checked-bg-image:       url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><circle cx="24" cy="24" r="17" fill="#{$body-bg}"/><path fill="#{$primary}" d="M24 33.3q3.9 0 6.6-2.7 2.7-2.7 2.7-6.6 0-3.9-2.7-6.6-2.7-2.7-6.6-2.7-3.9 0-6.6 2.7-2.7 2.7-2.7 6.6 0 3.9 2.7 6.6 2.7 2.7 6.6 2.7Z"/><path d="M24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>');
$form-check-radio-check-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><circle cx="24" cy="24" r="17" fill="#{$body-bg}"/><path fill="#{$primary}" d="M21.05 33.1 35.2 18.95l-2.3-2.25-11.85 11.85-6-6-2.25 2.25Z"/><path d="M24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>');
$form-check-input-indeterminate-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><rect x="9" y="9" width="30" height="30" fill="#{$body-bg}"/><path fill="#{$primary}" d="M12.5 25.4h23.05v-3H12.5Z"/><path d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z"/></svg>');

// Material expansion SVG icons (https://fonts.google.com/icons?icon.query=arrow+drop+down)
$form-select-indicator: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 -960 960 960"><path fill="#{body-color}" d="M480-360 280-560h400L480-360Z"/></svg>');
$form-select-bg-size: 2rem;

// Dropdown caret isn't used.
$enable-caret: false;
$dropdown-link-active-bg: transparent; // Note this prevents hover color, must be corrected locally

// Nav links in header
$nav-link-padding-x: 1rem;

// Navbar disable default icon and borders
$navbar-light-toggler-border-color: transparent;
$navbar-light-toggler-icon-bg:      '';
$navbar-toggler-focus-width:        0;
$navbar-toggler-padding-x:          0;
$navbar-toggler-padding-y:          0;
$navbar-padding-y:                  .8rem;
$navbar-nav-link-padding-x:         1rem;

// Accordions
$accordion-button-active-bg:    $body-bg;
$accordion-button-active-color: $body-color;
// Material expansion SVG icons (https://fonts.google.com/icons?icon.query=chevron&icon.style=Rounded)
$accordion-button-icon:         url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="#{$primary}" d="M24 30.15q-.3 0-.55-.1-.25-.1-.5-.35l-9.9-9.9q-.4-.4-.375-1.075.025-.675.425-1.075.5-.5 1.075-.425.575.075 1.025.475l8.8 8.8 8.8-8.8q.4-.4 1.075-.45.675-.05 1.075.45.5.4.425 1.05-.075.65-.475 1.1l-9.85 9.85q-.25.25-.5.35-.25.1-.55.1Z"/></svg>');
// Bootstrap rotates the icon, so we really only need one...
$accordion-button-active-icon:  $accordion-button-icon;

// Modals
$modal-content-border-width: 0;
$modal-inner-padding: 3rem;
$btn-close-padding-x: 0;
$btn-close-padding-y: 0;
$btn-close-bg: none;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;

// END bootstrap customizations

// Include bootstrap configuration after our overrides.
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';

// Our custom variables
$bluedeep:        #082732;
$gold:            #b37700;
$mineral-green:   #2a5a61;
$primary-10:      rgba($primary, .1);
$secondary-hover: #f22e2f;
$yellow-mustard:  #ffea9d;
$yellow-light:    rgba($yellow, .05);

// Checkup Status Colors
$checkup-done:        $primary;
$checkup-inprogress:  $teal;
$checkup-opportunity: $yellow;
$checkup-skip:        $gray-300;
$checkup-unanswered:  $gray-300;

// Measurements...
$topnav-height: 90px;

// Additional fonts
$icon-font-family: 'Material Symbols Rounded';
